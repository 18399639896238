import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Search, Confirm } from "semantic-ui-react";
import _ from "lodash";

// Custom components
import TableHeader from "components/atoms/Table/tableHeader";
import AddEditReportModal from "components/modals/Membership/MembershipReports/addEditReportModal";
import PaginationShorthand from "components/atoms/Pager";
import {
	PAGE_SIZE_FOR_PAGINATION,
	getCurrentlyShowingItemsInGrid,
	ItemsPerPage,
} from "helpers/utilCommon";
import RenderSelect from "components/atoms/Select";
import {
	MEMBERSHIPREPORTSHEADERINFO,
	getTempSortKey,
	SORTINGKEYS,
	sortingAfterSave,
	ReportListRefreshAction,
} from "models/Membership/membershipReportsModel";
import {
	getMembershipReportListAction,
	getReportTypeListAction,
	deleteReportAction,
} from "actions/Membership/membershipReportsAction";

const MembershipReportsList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [paginationInfo, setPaginationInfo] = useState({
		pageSize: PAGE_SIZE_FOR_PAGINATION,
		pageNumber: 1,
		totalItems: 0
	});
	const [reportList, setReportList] = useState([]);
	const [activePageData, setActivePageData] = useState([]);
	const [activeReportId, setActiveReportId] = useState(0);
	const [activeRowItem, setActiveRowItem] = useState(undefined);
	const [currentlyShowingItems, setCurrentlyShowingItems] = useState(false);
	const [sortKey, setSortKey] = useState(getTempSortKey());
	const [searchReportInput, setSearchReportInput] = useState("");
	const [filteredData, setFilterData] = useState([]);
	const [pageRefreshAction, setPageRefreshAction] = useState("");
	const [typeReportList, setReportTypeList] = useState([]);
	const [isSaveReport, setIsSaveReport] = useState(false);
	const [openDeletConfirmBox, setOpenDeleteConfirmBox] = useState(false);
	const { pageSize, pageNumber, totalItems } = paginationInfo;
	const { sortColumn, sortOrder } = sortKey;
	const dispatch = useDispatch();
	const props = useSelector((state) => {
		return {
			userPermission: state.commonReducer.userPermission,
			initialValues: state.reportsReducer.initialValues
				? state.reportsReducer.initialValues
				: undefined,
			messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
		};
	});

	useEffect(() => {
		if (pageRefreshAction === ReportListRefreshAction.OnSelect) {
			let searchKey = (searchReportInput || "").trim();
			setPageRefreshAction("");
			if (!_.isEmpty(searchKey)) {
				let listData = [...reportList];
				const results = listData.filter((p) =>
					String(p.Name.toLowerCase().trim()).includes(searchKey.toLowerCase()),
				);
				const offset = searchKey ? 0 : (pageNumber - 1) * pageSize;
				setPaginationInfo({ pageSize, pageNumber: 1, totalItems: results?.length || 0 });
				setActivePageData(results.slice(offset, offset + pageSize));
				updateCurrentlyShowingItems(1, results?.length);
				setIsLoading(false);
			}
		} else if (pageRefreshAction === ReportListRefreshAction.OnEnterKey) {
			let listData = [...reportList];
			let searchKey = (searchReportInput || "").trim();
			setPageRefreshAction("");
			setFilterData([]);
			const result = listData.filter(
				(p) =>
					String(p.Name.toLowerCase().trim()).startsWith(searchKey.toLowerCase()) ||
					String(p.BaseReportId.toLowerCase().trim()).startsWith(searchKey.toLowerCase()),
			);
			const offset = searchKey ? 0 : (pageNumber - 1) * pageSize;
			setPaginationInfo({ pageSize, pageNumber: 1, totalItems: result?.length || 0 });
			setActivePageData(result.slice(offset, offset + pageSize));
			updateCurrentlyShowingItems(1, result?.length);
			setIsLoading(false);
		} else if (
			_.isEmpty(searchReportInput) &&
			pageRefreshAction === ReportListRefreshAction.OnClearSearchText
		) {
			setFilterData([]);
			setPageRefreshAction("");
			setPaginationInfo({
				pageSize: PAGE_SIZE_FOR_PAGINATION,
				pageNumber: 1,
				totalItems: reportList?.length || 0
			});
			changeListDataOnPagination(PAGE_SIZE_FOR_PAGINATION, 1);
            updateCurrentlyShowingItems(1, reportList?.length);
		} else if (
			!_.isEmpty(searchReportInput) &&
			pageRefreshAction === ReportListRefreshAction.OnFilter
		) {
			let AutoSuggestList = [...reportList];
			const filteredSuggstList = AutoSuggestList.filter((p) => {
				if (
					String(p.Name.toLowerCase().trim()).includes(
						searchReportInput.toLowerCase()
					) ||
					String(p.BaseReportId).includes(searchReportInput)
				) {
					return p;
				}
			});
			setFilterData(filteredSuggstList);
		} else if (
			pageRefreshAction === ReportListRefreshAction.OnAdd ||
			pageRefreshAction === ReportListRefreshAction.OnEdit ||
			pageRefreshAction === ReportListRefreshAction.OnDelete
		) {
			setPageRefreshAction("");
            if(pageRefreshAction === ReportListRefreshAction.OnDelete){
               onSort(undefined, sortKey.sortColumn, true);
            }else{
                onSort(undefined, sortingAfterSave.sortColumn, true);
            }
			setIsSaveReport(false);
		}
	}, [pageRefreshAction, searchReportInput, totalItems, reportList]);

	const loadInitialList = (isCustomSort = false) => {
		getMembershipReportListAction(dispatch, (response) => {
			if (!_.isEmpty(response) && response?.ReportTypeList.length > 0) {
				setReportTypeList(response.ReportTypeList);
			}
			setSearchReportInput("");
            let activePageNumber= pageNumber;
            let activePageSize = pageSize;
            if(activeReportId > 0){
                const offset = (pageNumber - 1) * pageSize;
                const tCount=response.ReportList.slice(offset, offset + pageSize)?.length||0;
                activePageNumber= tCount === 0 ? 1 : pageNumber;
            }else{
                activePageNumber = 1;
                activePageSize = PAGE_SIZE_FOR_PAGINATION;
            }
			setPaginationInfo({
				pageSize: activePageSize,
				pageNumber: activePageNumber,
				totalItems: response.ReportList?.length || 0
			});
			setReportList(response?.ReportList || []);
            if (activeReportId > 0) {
                setPageRefreshAction(ReportListRefreshAction.OnDelete);
                updateCurrentlyShowingItems(activePageNumber, response.ReportList?.length || 0);
            }
			else if (isCustomSort) {
					setPageRefreshAction(
						activeRowItem?.ReportId > 0
							? ReportListRefreshAction.OnEdit
							: ReportListRefreshAction.OnAdd
					);
                    updateCurrentlyShowingItems(activePageNumber, response.ReportList?.length || 0);
				setIsSaveReport(true);
				closeModal();
			} else {
				setPageRefreshAction(ReportListRefreshAction.OnClearSearchText);
			}
		});
	};
	useEffect(() => {
		loadInitialList();
	}, []);

	const onSort = (event, sortKeyValue, allowCustomSorting = false) => {
		let orderType; // 0 for Ascending order, 1 for descending order
		if (!allowCustomSorting) {
			if (event.target.attributes.getNamedItem("data-order")) {
				orderType = event.target.attributes.getNamedItem("data-order").value;
			} else {
				orderType = event.target.parentElement.attributes.getNamedItem("data-order")?.value;
			}
		}
        if(pageRefreshAction === ReportListRefreshAction.OnDelete){
            orderType=sortKey.sortOrder
        }else{
            orderType = allowCustomSorting ? "1" : orderType;
        }

		let sortKeyName = sortKeyValue.split(" ").join("");

		let reportListData = [...reportList];
		if (reportListData && reportListData.length > 0 && sortKeyValue) {
			const offset = (pageNumber - 1) * pageSize;
			if (orderType === "0") {
				if (sortKeyName === SORTINGKEYS.ReportTitle || sortKeyName === SORTINGKEYS.ReportType) {
					reportListData.sort((a, b) => a[sortKeyName].localeCompare(b[sortKeyName]));
				} else if (sortKeyName === SORTINGKEYS.ReportID) {
					reportListData.sort((x, y) => x[sortKeyName] - y[sortKeyName]);
				}
			} else if (sortKeyName === SORTINGKEYS.ReportID) {
				reportListData.sort((x, y) => y[sortKeyName] - x[sortKeyName]);
			} else if (sortKeyName === SORTINGKEYS.ModifiedDate) {
				reportListData.sort((x, y) => new Date(y[sortKeyName]) - new Date(x[sortKeyName]));
			}
			setSortKey({ sortOrder: orderType, sortColumn: sortKeyName });
			setReportList(reportListData);
			setActivePageData([...reportListData.slice(offset, offset + pageSize)]);
		}
	};
	const handleResultSelect = (e, { result }) => {
		setSearchReportInput(result?.Name);
		setPageRefreshAction(ReportListRefreshAction.OnSelect);
	};

	const handleOnKeyDown = (e) => {
		if (e.which === 13) {
			setIsLoading(true);
			setFilterData([]);
			setPageRefreshAction(ReportListRefreshAction.OnEnterKey);
		}
	};

	const changeListDataOnPagination = (
		currentPageSize = pageSize,
		activePageNumber = pageNumber
	) => {
		let results = [...reportList];
		const offset = (activePageNumber - 1) * currentPageSize;
		setActivePageData(results.slice(offset, offset + currentPageSize));
		updateCurrentlyShowingItems(activePageNumber);
	};

	const onChangeNumberOfItemsPerPage = (e, value) => {
		setPaginationInfo({ pageSize: value, pageNumber: 1, totalItems });
		changeListDataOnPagination(value, 1);
	};

	const updateCurrentlyShowingItems = (activePageNumber = 1, totalcount = totalItems) => {
		setCurrentlyShowingItems(
			getCurrentlyShowingItemsInGrid(activePageNumber, pageSize, totalcount),
		);
	};

	const onPageChangeHandler = (event, data) => {
		changeListDataOnPagination(pageSize, data.activePage);
		setPaginationInfo({ pageSize, pageNumber: data.activePage, totalItems });
	};

	const closeModal = () => {
		setIsModalVisible(false);
		setActiveRowItem(undefined);
		setActiveReportId(0);
	};

	const handleSearchChange = (e, { value }) => {
		setSearchReportInput(value);
		if (_.isEmpty((value||'').trim())) {
			setPageRefreshAction("");
            setFilterData([]);
			setPageRefreshAction(ReportListRefreshAction.OnClearSearchText);
		} else {
			setPageRefreshAction(ReportListRefreshAction.OnFilter);
		}
	};

	const handleSearchIcon_Click = () => {
		setIsLoading(true);
		setFilterData([]);
		if (!_.isEmpty(searchReportInput)) {
			setPageRefreshAction(ReportListRefreshAction.OnEnterKey);
		}
		setIsLoading(false);
	};

	const resultRenderer = ({ Name }) => <Label content={Name} />;

	const openAddEditReportModal = () => {
		setActiveRowItem(undefined);
		setIsSaveReport(false);
		if (_.isArray(typeReportList) && typeReportList.length === 0) {
			getReportTypeListAction(dispatch, (response) => {
				setReportTypeList(response);
				setIsModalVisible(true);
			});
		} else {
			setIsModalVisible(true);
		}
	};

	const renderTableData = (data) => {
		if (data.length > 0) {
			return data.map((item) => {
				return (
					<tr key={item.BaseReportId}>
						<td>{item.Name}</td>
						<td>{item.BaseReportId}</td>
						<td>{item?.ReportType}</td>
						<td className="Email">
							<div className="truncatedText">
								<a href={item.URL} target="_blank">
									{item.URL}
								</a>
							</div>
						</td>
						{(props.userPermission.Delete || props.userPermission.Update) && (
							<td className="action">
								{props.userPermission.Update && (
									<button
										className="edit-btn"
										data-testid="editButton"
										onClick={() => {
											setIsModalVisible(true);
											setActiveRowItem(item);
										}}
									>
										<i aria-hidden="true" className="pencil icon squareIcon"></i>
										<span className="tooltop">Edit Report</span>
									</button>
								)}
								{props.userPermission.Delete && (
									<button
										className="delete-btn"
										data-testid="deleteReportButton"
										onClick={() => {
											setOpenDeleteConfirmBox(true);
											setActiveReportId(item.ReportId);
                                            setPageRefreshAction('');
										}}
									>
										<i aria-hidden="true" className="trash icon squareIcon"></i>
										<span className="tooltop">Delete Report</span>
									</button>
								)}
							</td>
						)}
					</tr>
				);
			});
		}
	};

	const deleteReport = () => {
        setOpenDeleteConfirmBox(false);
		let data = reportList.filter((item) => item.ReportId === activeReportId)[0];
		deleteReportAction(dispatch, data, (response) => {
			if (response.status) {
				setPageRefreshAction(ReportListRefreshAction.OnDelete);
				loadInitialList();
			}
		});
	};

	return (
		<div className="homePage" data-testid="manageReport">
			<div className="ui manage-reports-wrap">
				<div className="headingTitle clearfix">
					<h2>Manage Reports</h2>
					{props.userPermission.Add && (
						<button className="ui secondary button" onClick={openAddEditReportModal}>
							<i aria-hidden="true" class="plus icon"></i>Add New Report
						</button>
					)}
					<div className="searchBx">
						<div className="ui icon input">
							<Search
								onKeyDown={handleOnKeyDown}
								loading={isLoading}
								onResultSelect={handleResultSelect}
								onSearchChange={_.debounce(handleSearchChange, 500, { leading: true })}
								results={filteredData}
								value={searchReportInput}
								resultRenderer={resultRenderer}
								showNoResults={false}
								noResultsMessage={"No results found."}
								placeholder="Search Report by Report Title, Report ID"
								{...props}
							/>
							<span className="hiddenSearchIcon" onClick={handleSearchIcon_Click}></span>
						</div>
					</div>
				</div>
				<div className="tableWrapper" style={{ marginTop: "35px" }}>
					<table className="customTable meetingTypeTable">
						<thead>
							<tr>
								<TableHeader
									headerProps={MEMBERSHIPREPORTSHEADERINFO}
									onClickProps={onSort}
									resetHeader={isSaveReport}
								></TableHeader>
								{(props.userPermission.Delete || props.userPermission.Update) && (
									<th className="Actions" data-order="0" style={{ width: "10%" }}>
										Actions
									</th>
								)}
							</tr>
						</thead>
						<tbody>{renderTableData(activePageData)}</tbody>
					</table>
					{!_.isEmpty(searchReportInput) && activePageData.length <= 0 && (
						<div className="noRecordMessage">No results found.</div>
					)}
					{(!reportList ||
						(reportList && reportList.length === 0 && _.isEmpty(searchReportInput))) && (
						<div className="noRecordMessage">No reports added.</div>
					)}
				</div>
				{activePageData && activePageData.length > 0 && (
					<section className="pagerWrap">
						<div className="pager">
							<PaginationShorthand
								defaultActivePage={pageNumber}
								totalPages={Math.ceil(totalItems / pageSize)}
								onPageChange={onPageChangeHandler}
							/>
						</div>
						<div className="itemPerPage">
							<RenderSelect
								onchange={(event, value) => onChangeNumberOfItemsPerPage(event, value)}
								value={pageSize}
								options={ItemsPerPage}
							/>
							<span className="itemsPerPage">items per page</span>
						</div>
						<div className="totalPage">{currentlyShowingItems}</div>
					</section>
				)}
			</div>
			{isModalVisible && (
				<AddEditReportModal
					closeModal={closeModal}
					editRowItem={activeRowItem}
					refreshListingPage={(isRecordAdded) => loadInitialList(isRecordAdded)}
					typeReportList={typeReportList}
					{...props}
				/>
			)}
			<Confirm
				className="confirmBox"
				data-testid="confirmDeleteReport"
				cancelButton="NO"
				confirmButton="YES"
				content={props.messageCodes["7176"].text}
				onCancel={() => {
					setOpenDeleteConfirmBox(false);
					setActiveReportId(0);
				}}
				onConfirm={() => deleteReport()}
				open={openDeletConfirmBox}
			/>
		</div>
	);
};
export default MembershipReportsList;
